<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">会员设置</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-form ref="form" :model="form" label-width="160px" style="max-width: 750px;">
				<el-card shadow="never" class="mt-24">
					<div slot="header">
						<span>会员设置</span>
					</div>
					<el-row>
						<el-form-item label="非会员广告开关">
							<el-radio v-model="form.is_advertising" value="10" label="10">
								关闭
							</el-radio>
							<el-radio v-model="form.is_advertising" value="20" label="20">
								开启
							</el-radio>
						</el-form-item>
						<el-form-item label="非会员同时开展活动数">
							<el-input-number v-model="form.limit_coupon_num"></el-input-number>
						</el-form-item>
						<el-form-item label="非会员单活动发券数量">
							<el-input-number v-model="form.limit_stock_num"></el-input-number>
						</el-form-item>
						<el-form-item label="非会员总活动数量限制">
							<el-input-number v-model="form.limit_total_coupon_num"></el-input-number>
						</el-form-item>
						<p><h6>会员价格设置</h6></p>
						<el-form-item>
							<div class="d-flex">
								<p class="align-items-center mr-2">30天</p>
								<el-input-number :min="0.01" :step="0.01" v-model="form.price[30].price"></el-input-number>
							</div>
							<div class="d-flex">
								<p class="align-items-center mr-2">60天</p>
								<el-input-number :min="0.01" :step="0.01" v-model="form.price[60].price"></el-input-number>
							</div>
							<div class="d-flex">
								<p class="align-items-center mr-2">90天</p>
								<el-input-number :min="0.01" :step="0.01" v-model="form.price[90].price"></el-input-number>
							</div>
							<div class="d-flex">
								<p class="align-items-center mr-2">150天</p>
								<el-input-number :min="0.01" :step="0.01" v-model="form.price[150].price"></el-input-number>
							</div>
							<div class="d-flex">
								<p class="align-items-center mr-2">180天</p>
								<el-input-number :min="0.01" :step="0.01" v-model="form.price[180].price"></el-input-number>
							</div>
							<div class="d-flex">
								<p class="align-items-center mr-2">360天</p>
								<el-input-number :min="0.01" :step="0.01" v-model="form.price[360].price"></el-input-number>
							</div>
						</el-form-item>
					</el-row>
				</el-card>
				<el-form-item class="mt-2">
					<el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				loading: false,
				form: {
					is_advertising: '10',
					limit_coupon_num: 50,
					limit_stock_num: 0,
					limit_total_coupon_num: 0,
					price: {
						30: {
							// 天数
							days_num: 90,
							// 价格
							price: 40.00,
						},
						60: {
							// 天数
							days_num: 90,
							// 价格
							price: 40.00,
						},
						90: {
							// 天数
							days_num: 90,
							// 价格
							price: 40.00,
						},
						150: {
							// 天数
							days_num: 90,
							// 价格
							price: 40.00,
						},
						180: {
							// 天数
							days_num: 90,
							// 价格
							price: 40.00,
						},
						360: {
							// 天数
							days_num: 90,
							// 价格
							price: 40.00,
						},
					},
				},
			}
		},
		created() {
			this.getSetting();
		},
		methods: {
			getSetting() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/member/setting',
					},
					method: 'get',
				}).then(res => {
					this.form = res.data.data
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},

			// 提交
			submit() {
				this.loading = true;
				this.axios({
					token: true,
					params: {
						s: 'store/member/setting',
					},
					data: {
						member: this.form
					},
					method: 'post',
				}).then(res => {
					if (res.data.code == 1) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
						// 跳转后台首页
						this.$router.push({
							name: 'member/setting'
						})
					} else {
						this.$message({
							message: res.data.msg,
							type: 'error'
						});
					}
					this.loading = false;
				}).catch(err => {
					this.loading = false;
				})
			},
		},
	}
</script>

<style>

</style>
